import { humanReadableBytes } from "@faro-lotv/foundation";
import { LinearProgress, Stack } from "@mui/material";
import { NO_TRANSLATE_CLASS } from "../../types";
import { FaroButton } from "../button/faro-button";
import { FaroVar } from "../faro-var";
import {
  FaroIconButton,
  FaroIconButtonSizes,
} from "../icon-button/faro-icon-button";
import { CloseIcon } from "../icons/icons";
import { FaroText } from "../text/faro-text/faro-text";
import { CircularProgress } from "./circular-progress";
import { RemainingTimeLabel } from "./remaining-time-label";

export interface FileProgressProps {
  /** Whether to disable interactions on the component */
  disabled?: boolean;

  /** The file to show progress for */
  file: File;

  /**
   * Custom file name to use for rendering
   * If not set, the name attribute of the file object will be used instead
   */
  fileName?: string;

  /** The current progress to show in percentage. Leave undefined to show no progress. */
  progress?: number;

  /** The estimated ms epoch time of completion. Leave undefined to show no time estimate. */
  expectedEnd?: number;

  /** Callback executed when the user cancels the progress */
  onCancel?(): void;
}

/**
 * @returns the progress with file information and a cancel button.
 */
export function FileProgress({
  disabled,
  file,
  expectedEnd,
  progress,
  onCancel,
}: FileProgressProps): JSX.Element {
  return (
    <Stack sx={{ p: 1 }}>
      <Stack
        sx={{ minHeight: "24px" }}
        direction="row"
        justifyContent="space-between"
      >
        <FaroText
          sx={{
            alignSelf: "end",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          variant="bodyM"
          className={NO_TRANSLATE_CLASS}
        >
          {file.name}
        </FaroText>
        {onCancel && (
          <FaroIconButton
            size={FaroIconButtonSizes.xs}
            onClick={onCancel}
            disabled={disabled}
          >
            <CloseIcon />
          </FaroIconButton>
        )}
      </Stack>
      <Stack sx={{ mt: 0.5 }} direction="row" justifyContent="space-between">
        <FaroText variant="labelM" sx={{ opacity: 0.6 }}>
          <FaroVar>{humanReadableBytes(file.size)}</FaroVar>
          {expectedEnd && <FaroVar> • </FaroVar>}
          {expectedEnd && <RemainingTimeLabel expectedEnd={expectedEnd} />}
        </FaroText>
        {progress !== undefined && (
          <FaroText variant="bodyS" className={NO_TRANSLATE_CLASS}>
            {Math.round(progress)}%
          </FaroText>
        )}
      </Stack>

      {progress !== undefined && (
        <LinearProgress
          sx={{
            mt: 1,
            "& .MuiLinearProgress-bar": {
              background: (theme) => theme.palette.primary.main,
            },
          }}
          variant="determinate"
          value={progress}
        />
      )}
    </Stack>
  );
}

/**
 * @returns the progress with file information and a cancel button.
 */
export function FileProgressCircular({
  disabled,
  file,
  fileName,
  expectedEnd,
  progress,
  onCancel,
}: FileProgressProps): JSX.Element {
  return (
    <Stack alignItems="center" justifyContent="center" flexGrow={1}>
      {progress !== undefined && (
        <CircularProgress
          variant="determinate"
          sx={{
            color: "blue500",
            mb: 2.5,
          }}
          size={60}
          value={progress}
          showPercentage
        />
      )}

      <Stack
        sx={{ minHeight: "24px" }}
        direction="column"
        justifyContent="space-between"
      >
        <FaroText
          sx={{
            alignSelf: "end",
            overflow: "hidden",
            textOverflow: "ellipsis",
            mb: 1,
          }}
          variant="heading16"
        >
          Uploading <FaroVar>{fileName ?? file.name}</FaroVar>
        </FaroText>
        {expectedEnd && (
          <FaroText variant="placeholder">
            <RemainingTimeLabel expectedEnd={expectedEnd} />
          </FaroText>
        )}
        {onCancel && (
          <FaroButton
            variant="ghost"
            sx={{ mt: 5 }}
            onClick={onCancel}
            disabled={disabled}
          >
            Cancel
          </FaroButton>
        )}
      </Stack>
    </Stack>
  );
}
