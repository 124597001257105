import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { FontWeights } from "../faro-theme";
import { OpenLinkIcon } from "../icons/icons";

export type FeedBackForm = {
  /** label to display on menu item in FeedbackMenu */
  label: ReactNode;
  /** link to feed back form */
  link: string;
};

export type FeedbackMenuProps = {
  /** Property that describes if the menu is open or not. */
  isOpen: boolean;
  /** Function to call when the menu should close. */
  onClose(): void;
  /** Element to anchor the FeedbackMenu to. */
  anchorEl?: HTMLButtonElement;
  /** Array of labels and links to the various feedback forms. */
  feedbackForms: FeedBackForm[];
};

/**
 * @returns a menu that anchors itself to an element and displays multiple feed back form buttons
 */
export function FeedbackMenu({
  isOpen,
  onClose,
  anchorEl,
  feedbackForms,
}: FeedbackMenuProps): JSX.Element {
  return (
    <Menu anchorEl={anchorEl} open={isOpen} onClose={onClose}>
      <MenuItem sx={{ pointerEvents: "none" }}>
        <Stack sx={{ mr: 2 }}>
          <Typography sx={{ fontWeight: FontWeights.SemiBold }}>
            Send feedback to ...
          </Typography>
        </Stack>
      </MenuItem>
      <Divider variant="middle" />
      {feedbackForms.map((item, index) => (
        <MenuItem
          key={index}
          href={item.link}
          target="_blank"
          component="a"
          onClick={onClose}
        >
          <ListItemText>{item.label}</ListItemText>
          <ListItemIcon>
            <OpenLinkIcon sx={{ color: "gray850" }} />
          </ListItemIcon>
        </MenuItem>
      ))}
    </Menu>
  );
}
