import { PropsWithChildren } from "react";
import { NO_TRANSLATE_CLASS } from "../../types/localize-types";

/**
 * @returns A component whose children will not be translated by localizeJS
 */
export function NoTrasl({ children }: PropsWithChildren): JSX.Element {
  return (
    <span className={NO_TRANSLATE_CLASS} style={{ pointerEvents: "none" }}>
      {children}
    </span>
  );
}
