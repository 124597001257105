import { ThreeEvent, useFrame } from "@react-three/fiber";
import { DomEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import { useRef, useState } from "react";
import {
  BufferGeometry,
  CircleGeometry,
  DoubleSide,
  Euler,
  Group,
  Mesh,
  MeshBasicMaterial,
  Quaternion,
  Texture,
  Vector3,
  Vector3Tuple,
} from "three";
import { clamp, inverseLerp, lerp } from "three/src/math/MathUtils.js";
import { useOnClick } from "../../hooks";

const DEFAULT_CIRCLE_SIZE = 0.3;
const DEFAULT_CIRCLE_SEGMENTS = 64;
const DEFAULT_GEOMETRY = new CircleGeometry(
  DEFAULT_CIRCLE_SIZE,
  DEFAULT_CIRCLE_SEGMENTS,
);

const HOVER_SCALE_FACTOR = 1.5;

/** Value used for the fade off effect */
const FADE_OFF_VALUES = {
  /** Below this distance the placeholders are rendered with opacityNear */
  nearDist: 1,

  /** Between nearDist and nearDist + distRange the opacity is interpolated between opacityNear and opacityFar */
  distRange: 10,

  /**
   * A multiplier factor on the altitude while computing the distance, so a little change in altitude will
   * result in a higher distance, increasing the transparency faster
   */
  altitudeFactor: 85,

  /**
   * An extra altitude offset applied to the placeholder position when computing the opacity to compensate
   * the fact that placeholders above the users are always closer to the camera than the one below, to still allow users
   * to walk up and back down stairs
   */
  altitudeAdjustment: 1.5,

  /** Opacity value for the placeholders near the user */
  opacityNear: 0.95,

  /** Opacity values for the placeholders far from the user before they disappear */
  opacityFar: 0,

  /** Opacity value for hovered placeholders */
  opacityHover: 1,

  /** Speed at which the opacity value changes during the hover animation*/
  hoverFadeSpeed: 0.1,
};

/** The default mesh is vertical, this rotates it to face up so it can look like it's on the floor */
const DEFAULT_ROTATION = new Quaternion().setFromEuler(
  new Euler(-Math.PI / 2, 0, 0),
);

/** The parameters for creating a pano placeholder */
export interface PanoramaPlaceholderProps {
  /** The position of this placeholders */
  position: Vector3;

  /** True if the placeholders should fade off by distance */
  shouldFadeOff: boolean;

  /** The distance at which the fading starts */
  fadeDistance?: number;

  /** True if the placeholder should face the camera */
  shouldFaceCamera?: boolean;

  /** Callback for when this placeholder is clicked */
  onClicked(ev: ThreeEvent<DomEvent>): void;

  /** Url to the default texture */
  defaultTexture: Texture;

  /** Url to the hovered texture */
  hoverTexture: Texture;

  /** The geometry used to render the placeholders */
  geometry?: CircleGeometry;
}

function heightWeightedDistance(
  a: Vector3,
  b: Vector3,
  altitudeFactor: number,
): number {
  const dx = a.x - b.x;
  const dy = a.y - b.y;
  const dz = a.z - b.z;
  return Math.sqrt(dx * dx + dy * dy * altitudeFactor + dz * dz);
}

/**
 * Creates a placeholder for navigation within a panorama image
 *
 * @returns a Panorama placeholder
 */
export function PanoramaPlaceholder({
  position,
  shouldFadeOff,
  fadeDistance,
  shouldFaceCamera,
  onClicked,
  defaultTexture,
  hoverTexture,
  geometry = DEFAULT_GEOMETRY,
}: PanoramaPlaceholderProps): JSX.Element {
  const defaultMeshRef = useRef<Mesh<BufferGeometry, MeshBasicMaterial> | null>(
    null,
  );
  const hoverMeshRef = useRef<Mesh<BufferGeometry, MeshBasicMaterial> | null>(
    null,
  );
  const groupRef = useRef<Group>(null);

  const [isCursorHovering, setIsCursorHovering] = useState<boolean>(false);
  const [isHoverVisible, setIsHoverVisible] = useState<boolean>(false);

  const worldPos = useRef<Vector3>(new Vector3());

  // Each frame we modify the opacity of the placeholder to fade between the default and hovered state
  // https://docs.pmnd.rs/react-three-fiber/advanced/pitfalls#%E2%9C%85-instead,-use-refs-and-mutate
  useFrame(({ camera }) => {
    // Early return if the placeholder mesh is note ready
    if (!defaultMeshRef.current) return;

    if (groupRef.current) {
      // If the placeholder should face the camera, set the quaternion of the group to the camera's quaternion
      groupRef.current.quaternion.copy(
        shouldFaceCamera ? camera.quaternion : DEFAULT_ROTATION,
      );
    }

    // we want the opacity of the placeholders to fade as their distance from the camera increases
    // to help the scene feel less cluttered
    // To do this we have a near and far cutoff for where the fade should begin and end
    // and opacity values for what the placeholders should have at those extremes
    if (shouldFadeOff) {
      worldPos.current = defaultMeshRef.current
        .getWorldPosition(worldPos.current)
        .setY(worldPos.current.y + FADE_OFF_VALUES.altitudeAdjustment);
      const distance = heightWeightedDistance(
        camera.position,
        worldPos.current,
        FADE_OFF_VALUES.altitudeFactor,
      );
      // get the percent distance this placeholder is between near and far
      const nearDist = fadeDistance ?? FADE_OFF_VALUES.nearDist;
      let distPercentage = inverseLerp(
        nearDist,
        nearDist + FADE_OFF_VALUES.distRange,
        distance,
      );

      // would be nice if inverseLerp clamped, but it doesn't
      distPercentage = clamp(distPercentage, 0, 1);

      // get the distance corrected opacity value for this placeholder
      defaultMeshRef.current.material.opacity = lerp(
        FADE_OFF_VALUES.opacityNear,
        FADE_OFF_VALUES.opacityFar,
        distPercentage,
      );
    } else {
      defaultMeshRef.current.material.opacity = 1;
    }

    // we don't want to set visible to false here only to true if we are hovered
    // it will be set to false later when the fade out completes
    if (isCursorHovering) {
      setIsHoverVisible(true);
    }

    // it will take at least a frame for ref2 to be created after we set hoverVisible to true
    // wait until we have a ref to work with
    if (!hoverMeshRef.current) {
      return;
    }

    // move the opacity of the hover object towards its desired opacity
    hoverMeshRef.current.material.opacity = moveTowards(
      hoverMeshRef.current.material.opacity,
      isCursorHovering ? FADE_OFF_VALUES.opacityHover : 0,
      FADE_OFF_VALUES.hoverFadeSpeed,
    );

    // when the fade out completes, set hoverVisible to false
    if (!(isCursorHovering || hoverMeshRef.current.material.opacity > 0)) {
      setIsHoverVisible(false);
    }
  });

  const { onPointerDown, onClick } = useOnClick(onClicked);

  const scale: Vector3Tuple = isHoverVisible
    ? [HOVER_SCALE_FACTOR, HOVER_SCALE_FACTOR, HOVER_SCALE_FACTOR]
    : [1, 1, 1];

  return (
    <group ref={groupRef} renderOrder={10} position={position} scale={scale}>
      <mesh
        ref={defaultMeshRef}
        name="default"
        geometry={geometry}
        onPointerEnter={() => setIsCursorHovering(true)}
        onPointerLeave={() => setIsCursorHovering(false)}
        onPointerDown={onPointerDown}
        onClick={onClick}
      >
        <meshBasicMaterial
          side={DoubleSide}
          attach="material"
          map={defaultTexture}
          transparent={true}
        />
      </mesh>
      {
        /* no need to draw two meshes for every placeholder, only draw the second one for hovered placeholders */
        isHoverVisible && (
          <mesh ref={hoverMeshRef} name="hovered" geometry={geometry}>
            <meshBasicMaterial
              attach="material"
              map={hoverTexture}
              transparent={true}
              opacity={0}
            />
          </mesh>
        )
      }
    </group>
  );
}

/**
 * Steps a value towards target by at most maxDelta without overshooting
 *
 * @param current The current value
 * @param target The target value
 * @param maxDelta The max change of value towards target
 * @returns An update of current to make it closer or equal to target
 */
function moveTowards(
  current: number,
  target: number,
  maxDelta: number,
): number {
  if (Math.abs(target - current) <= maxDelta) {
    return target;
  }
  return current + Math.sign(target - current) * maxDelta;
}
