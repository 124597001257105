import { MenuItem, MenuItemProps, Stack, SvgIconProps } from "@mui/material";
import { ForwardedRef, ReactNode, forwardRef } from "react";
import { ColorString, blue, cyan, neutral } from "../colors";
import { Checkmark3Icon } from "../icons";
import { FaroText } from "../text/faro-text/faro-text";

type SingleSelectItemColors = {
  color: ColorString;
  subTitleColor: ColorString;
  highlightColor: ColorString;
  highlightBackground: ColorString;
  focusBorder: ColorString;
};

const LIGHT_COLORS: Readonly<SingleSelectItemColors> = Object.freeze({
  color: neutral[800],
  subTitleColor: neutral[600],
  highlightColor: blue[500],
  highlightBackground: neutral[500],
  focusBorder: neutral[999],
});

const DARK_COLORS: Readonly<SingleSelectItemColors> = Object.freeze({
  color: neutral[100],
  subTitleColor: neutral[300],
  highlightColor: cyan[400],
  highlightBackground: neutral[500],
  focusBorder: neutral[0],
});

export type SingleSelectItemProps = MenuItemProps & {
  /** If set to `true`, use the dark theme design variant. */
  dark?: boolean;

  /** If set to `true`, the text will be capitalized. */
  shouldCapitalize?: boolean;

  /** SvgIcon component to use as an icon for the MenuItem, needs to be passed as a component to be styled internally */
  Icon?(props: SvgIconProps): JSX.Element;

  /** The text to display for this item, falls back to the `value` if not defined. */
  label?: ReactNode;

  /** The secondary text of the item */
  secondaryText?: ReactNode;

  /**
   * The value which is currently selected.
   *
   * If it's the same as the value of this item, a checkmark icon will be displayed.
   */
  selectedValue?: MenuItemProps["value"];

  /**
   * The MUI select component can override the `value` prop and rename it to "data-value".
   *
   * See https://stackoverflow.com/a/66948157.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "data-value"?: MenuItemProps["value"];
};

/**
 * @returns an item in a selection menu, like a dropdown or button menu.
 *
 * For more complex menu items see `FaroMenuItem`.
 */
export const SingleSelectItem = forwardRef(function SingleSelectItem(
  {
    dark,
    value: itemValue,
    "data-value": dataValue,
    selectedValue,
    Icon,
    label,
    secondaryText,
    shouldCapitalize,
    sx,
    ...rest
  }: SingleSelectItemProps,
  ref: ForwardedRef<HTMLLIElement>,
): JSX.Element {
  const colors = dark ? DARK_COLORS : LIGHT_COLORS;

  // WORKAROUND: The `value` prop is renamed to `data-value` by the MUI `Select` component.
  // See https://stackoverflow.com/a/66948157
  const value = itemValue ?? dataValue;

  const isSelected = selectedValue === value;

  return (
    <MenuItem
      {...rest}
      ref={ref}
      value={value}
      selected={isSelected}
      sx={[
        {
          paddingX: 1.5,
          paddingY: 1,
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          textTransform: shouldCapitalize ? "capitalize" : "none",
          color: colors.color,
          "&:hover": {
            color: colors.highlightColor,
            background: `${colors.highlightBackground}26`,
          },
          "&:focus-visible": {
            color: colors.color,
            outline: `1px solid ${colors.focusBorder}`,
          },
          "&.Mui-selected": {
            color: colors.highlightColor,
            background: `${colors.highlightBackground}40`,
            "&:focus-visible": {
              color: colors.highlightColor,
              background: `${colors.highlightBackground}40`,
              outline: `1px solid ${colors.focusBorder}`,
            },
          },
          // Change ripple color when the option is pressed
          "&& .MuiTouchRipple-child": {
            backgroundColor: `${colors.highlightBackground}40`,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack width="100%">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row" alignItems="center" gap={1}>
            {Icon && <Icon sx={{ height: "1rem", width: "1rem" }} />}

            <FaroText variant="bodyM" color="inherit">
              {label ?? value}
            </FaroText>
          </Stack>
          {isSelected && (
            <Checkmark3Icon sx={{ height: "1rem", width: "1rem" }} />
          )}
        </Stack>
        {secondaryText && (
          <FaroText variant="bodyS" color={colors.subTitleColor}>
            {secondaryText}
          </FaroText>
        )}
      </Stack>
    </MenuItem>
  );
});
